import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Axios, routes } from '../../../config';
import Loader from '../../../loader';
import { error } from '../../../services/error';
import { Toast } from '../../../services/error/notification';
import styles from './index.module.scss'
import {BiSolidHide} from 'react-icons/bi'
import {FaEye} from 'react-icons/fa'
export class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastname: "",
            email: "",
            password: "",
            phone: "",
            loading: false,
            role: "",
            verification:false,
            otp:"",
            passwordHide:true
        }
    }


    handleRoleChange = (event) => {
        this.setState({ role: event.target.value });
    };

    
    seePassword = () => {
        const { passwordHide } = this.state;
        this.setState({ passwordHide: !passwordHide })
        return
}

    takeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    toggleRegister(e) {
        e.preventDefault();
        this.setState({ loading: true });
        const {
            name, lastname, email, password, role,phone
        } = this.state;

        const data = { name, lastname, email, password, role ,phone}


        Axios.post(routes.register, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                      );
                    this.setState({verification:true})
                    return;
                }
            }).catch(err => {
                error(err);
                return console.log(err)
            }).finally(() => {
                this.setState({  name: '',
                lastname: "",
                password: "",
                phone: "",
                loading: false,
                role: ""});
                return;
            })

        return
    }

    resendOtp=(e)=> {
        e.preventDefault();

        this.setState({ loading: true });

        const{email}=this.state;
        const data = { email }
        
        Axios.post(routes.resendOtp, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                      );
                    return;
                }
            }).catch(err => {
                error(err);
                return console.log(err)
            }).finally(() => {
                this.setState({ 
                loading: false,
                otp:"",
                });
                return;
            })
    }

    toggleVerify(e){
        e.preventDefault();

        this.setState({ loading: true });
        const {
            email,otp
        } = this.state;

        const data = { email,otp }

        
        Axios.post(routes.verifyOtp, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                      );
                    window.location.href='/auth/login'
                    return;
                }
            }).catch(err => {
                error(err);
                return console.log(err)
            }).finally(() => {
                this.setState({
                loading: false,
                otp:"",
                });
                return;
            })
    }

    render() {
        const{verification,loading,passwordHide}=this.state;

        if (loading) {
            return <Loader />
        }

        return (
            <div className='loginPopup'>
                {/* <div className='back' onClick={this.props.hide}/> */}
               { !verification?<div className="login-page">
                    <div className="form">
                        <form onSubmit={(e) => this.toggleRegister(e)} className="register">
                            <input type="text" placeholder="Name" minLength={3} maxLength={20} required name='name' onChange={(e) => { this.takeInput(e) }} />
                            <input type="text" placeholder="lastname" maxLength={20} name='lastname' onChange={(e) => { this.takeInput(e) }} />
                            <input type="email" placeholder="email" maxLength={50} required name='email' onChange={(e) => { this.takeInput(e) }} />
                            <input type="tel" placeholder="9999999" maxLength={10} minLength={10}  name='phone' onChange={(e) => { this.takeInput(e) }} />
                            <div>
                                <select required className='select' id="role" value={this.state.role} onChange={this.handleRoleChange}>
                                    <option value="">Select</option>
                                    <option value="Student">Student</option>
                                    <option value="Teacher">Teacher</option>
                                    <option value="HOD">HOD</option>
                                </select>
                            </div>
                            <div className='seePassword'>
                            <input type={passwordHide&&"password"} minLength={6} maxLength={50} required placeholder={"password"} name='password' onChange={(e) => { this.takeInput(e) }} />
                            {passwordHide ? <span className='pointer' onClick={() => { this.seePassword() }}><FaEye size={"1.2rem"} /></span> :
                                    <span className='pointer' onClick={() => { this.seePassword("password") }}><BiSolidHide size={"1.2rem"} /></span>}
                            </div>

                            <button type='submit' >Register</button>
                            <p className="message">Already registered? <Link to={'/auth/login'}>Sign In</Link></p>
                        </form>
                    </div>
                </div>:
                <div className='loginPopup'>
                <div className='login-page'>
                     <form className="form" onSubmit={(e) => this.toggleVerify(e)} >
                            <div className={styles.otp}>verify Otp send on your email</div>
                            <input type="text"  minLength={3} maxLength={20} required name='otp' onChange={(e) => { this.takeInput(e) }} />
                            <button type='submit' >Verify</button>
                            <p onClick={this.resendOtp} className={styles.resend}>Resend Otp</p>
                        </form>
                </div>
                </div>
                }
            </div>
        )
    }
}

export default Register