import React, { useEffect, useState } from 'react'
import styles from './../page.module.scss'
import { Axios, IMG_URL, PROFILEPIC, routes } from '../../../config'
import { Link, useParams } from 'react-router-dom'
import { error } from '../../../services/error'
import Loader from '../../../loader'
import TeachersProfile from '../../../teachersProfile'
import { Toast } from '../../../services/error/notification'

const Dep = () => {

  const { name } = useParams()

  const [data, setData] = useState([])
  const [galary, setGalary] = useState([])
  const [feature, setFeature] = useState([])
  const [topper, setTopper] = useState([])
  const [deptId, setDeptId] = useState()
  const [loading, setLoading] = useState(true)


  const getTopper = () => {
    setLoading(true);
    Axios.get(`${routes.topperByDept}?deptId=${deptId}`)
      .then((d) => {
        if (d.data) {
          setTopper(d.data.list)
          if (d.data.mess) {
            Toast("Message", d.data.mess, "info")
          }
          return;
        }
      }).catch(err => {
        return error(err);
      }).finally(() => {
        setLoading(false)
      })
  }

  const getList = () => {
    setLoading(true);
    setData([])
    setTopper([])
    setGalary([])
    Axios.get(`${routes.getdepartmentName}?name=${name}`).then((result) => {
      if (result) {
        setData(result?.data?.list[0]);
        setDeptId(result?.data?.list[0]?.id)
        if (result?.data?.mess) {
          Toast("Message", result.data.mess, 'info')
        }
        setLoading(false)
        return;
      }
      return;
    }).catch((err) => {
      error(err)
      return console.log(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  const getFeatureList = () => {
    setLoading(true);
    setFeature([])
    Axios.get(`${routes.getfeatureByDept}?deptId=${deptId}`).then((result) => {
      if (result) {
        setFeature(result.data.list);
        setLoading(false)
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  const getGalaryList = () => {
    setLoading(true);
    setGalary([])
    Axios.get(`${routes.getgalaryByDept}?deptId=${deptId}`).then((result) => {
      if (result) {
        setGalary(result.data.list);
        setLoading(false)
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    getList();
  }, [name])

  useEffect(() => {
    if (deptId) {
      getTopper();
      getGalaryList();
      getFeatureList()
    }
  }, [deptId])



  if (loading) {
    return <Loader />
  }

  return (

    <div className={styles.department}>
      <div className={styles.container}>

        {data?.image &&
          <div >
            <img src={`${IMG_URL + data?.image}`} />
          </div>
        }

        <div className={styles.content}>
          {data?.about}
        </div>



      </div>

      <div className={styles.student}>

        <div>

          {/* {data?.hod && <div className={styles.courseName}>Head Of {data?.name} Department</div>} */}

          <div className={styles['hod']}>

            {data?.hod && <TeachersProfile teachers={data?.hod} />}

          </div>

          {/* {data?.teachers.length > 0 && <div className={styles.courseName}>Teachers Of {data?.name} Department</div>} */}

          <div className={styles['student-list']}>
            {data?.teachers?.map(teacher => (
              <div key={teacher.id}>
                <TeachersProfile teachers={teacher} />
              </div>
            ))}

          </div>

        </div>


      </div>


      <div className={styles.galaryList}>
        {feature?.length > 0 && <h1>Feature </h1>}

        <div className={styles.container}>
          {
            feature?.map(d => {
              return (
                <Link to={`/feature/${d.id}`} key={d.id} className={styles.album}>
                  <div className={styles.photo}>
                    <div className={styles.layer}>
                      <div className={styles.layer}>
                        <div className={styles.layer}>
                          <img src={IMG_URL + d.images[0]?.photo} alt="feature" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.category}>
                    {d.name}
                  </div>
                  {/* <div className={styles.count}>
                    {d.imageCount} Photo
                  </div> */}
                </Link>
              )
            })
          }

        </div>

      </div>

      <div className={styles.galaryList}>
        {galary?.length > 0 && <h1>Galary </h1>}

        <div className={styles.container}>
          {
            galary?.map(d => {
              return (
                <Link to={`/galary/${d.id}`} key={d.id} className={styles.album}>
                  <div className={styles.photo}>
                    <div className={styles.layer}>
                      <div className={styles.layer}>
                        <div className={styles.layer}>
                          <img src={IMG_URL + d.images[0]?.photo} alt="galary" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.category}>
                    {d.category}
                  </div>
                  {/* <div className={styles.count}>
                    {d.imageCount} Photo
                  </div> */}
                </Link>
              )
            })
          }

        </div>

      </div>

      <div className={styles.toppers}>
        <h1>{(data?.name && topper.length > 0) && `Toppers of ${data.name} Department`}</h1>
        <div className={styles.topper}>
          {
            topper?.map(t => {
              return (

                <div className={styles.student}>
                  {t.photo ? <img src={IMG_URL + t.photo} alt="" /> : <img src={PROFILEPIC} alt="" />}
                  <div className={styles.name}>
                    {t.name}
                  </div>
                  <div className={styles.father}>
                    {t?.father && "Child of " + t.father}
                  </div>
                  <div >
                    {t?.course?.name && t?.course?.name + " " + t?.year + " Year"}
                  </div>
                  <div className="rank">
                    {t.rank === 1 ? t.rank + "st" : t.rank === 2 ? t.rank + "nd" : t.rank === 3 ? t.rank + "rd" : t.rank !== null ? t.rank + "th" : ""}  {t?.rank && "Rank"}
                  </div>
                  <div className="percent">
                    {t?.percentage && t?.percentage + "%"}
                  </div>



                </div>
              )
            })
          }

        </div>
      </div>

    </div>
  )
}

export default Dep;