import { Toast } from "./notification";

const error=(err)=>{
    if (err.response?.data) {
        Toast(
            "Server Message!!",err.response.data?.mess,"danger"
        )
        return;
    }
    else if (err.message) {
        Toast(
            "Server Error!!",err.message,"danger"
        )
        return;
    }
}

export  {error};