import React, { useEffect, useState } from 'react'
import { Axios, routes } from '../../config';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../loader';
import HTMLReactParser from 'html-react-parser'
import styles from './index.module.scss'
import { formatCreatedAt } from '../../services/formateDate/dateFormat';

const Notification = () => {

  const [news, setNews] = useState({})
  const [loading, setLoading] = useState(false)
  const params = useParams();

  const getNews = (id) => {
    setLoading(true);
    Axios.get(`${routes.getNotificationById}?id=${id}`).then((result) => {
      if (result) {
        setNews(result.data.list[0]);
        return;
      }
      return;
    }).catch((err) => {
      return console.error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    const { id } = params;
    getNews(id);
    window.scrollTo(0,0);
  }, [params])

  if (loading) {
    return <Loader />
  }
  return (
    <div className={styles.news}>
      <div className={styles.title}>

       {news?.link?
        <Link to={news.link} target='_blank'>Click to View {news.title}</Link>:
        news?.title
      }
      </div>

      <div className={styles.date}>
        {formatCreatedAt(news?.createdAt)} / By Admin
      </div>

      <div className='ql-editor'>
      {
        HTMLReactParser("" + news?.content)
      }
      </div>

    </div>
  )
}

export default Notification