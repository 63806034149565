import axios from "axios";

const API = process.env.REACT_APP_SERVER ? process.env.REACT_APP_SERVER : "http://localhost:4000/api";
const IMG_URL = process.env.REACT_APP_IMG_URL ? process.env.REACT_APP_IMG_URL : "http://localhost:4000/public";
const ROOT_URL = process.env.REACT_APP_ROOT_URL ? process.env.REACT_APP_ROOT_URL : "";
const ADMIN_PANEL = process.env.REACT_APP_ADMIN_PANEL ? process.env.REACT_APP_ADMIN_PANEL : "http://localhost:3000";
const PROFILEPIC=IMG_URL+'image/profile.jpg'
const Axios = axios.create({
    baseURL: API,
    headers: {
        "Content-Type": 'application/json',
        "Access-Control-Allow-Origin": "*"
    }
})

const routes = {


    //Authentication

    register: `${API}/user/register`,
    login: `${API}/user/login`,
    resetPassword: `${API}/user/resetPassword`,
    getOtp: `${API}/user/getOtp`,
    verifyOtp: `${API}/user/verifyOtp`,
    resendOtp: `${API}/user/resendOtp`,
    forgetPassword: `${API}/user/forgetPassword`,
    resetPassword: `${API}/user/resetPassword`,

    //course

    getcourse: `${API}/course/list`,
    getcourseByDepId: `${API}/course/getCourseByDepId`,
    getCourseId: `${API}/course/getCourseId`,
    getCourseById: `${API}/course/getCourseById`,

    //syllabus

    getsyllabus: `${API}/syllabus/list`,
    getsyllabusById: `${API}/syllabus/getsyllabusById`,
    getsyllabusId: `${API}/syllabus/getSyllabusId`,

    //departments

    getdepartment: `${API}/department/list`,
    getdepartmentById: `${API}/department/getdepartmentById`,
    getdepartmentId: `${API}/department/getdepartmentId`,
    getdepartmentName: `${API}/department/getdepartmentByName`,

    //galary

    getgalary: `${API}/galary/galary`,
    getgalaryById: `${API}/galary/galaryById`,
    getgalaryByDept: `${API}/galary/galaryByDept`,

    //galary

    getfeature: `${API}/feature/feature`,
    getfeatureById: `${API}/feature/featureById`,
    getfeatureByDept: `${API}/feature/featureByDept`,

    //comments

    getComments: `${API}/comment/list`,
    createComment: `${API}/comment/create`,
    deleteComment: `${API}/comment/delete`,
    getCommentById: `${API}/comment/getCommentById`,

    //News

    getNewsById: `${API}/News/getById`,
    getNewsId: `${API}/News/getNewsId`,
    getSlides: `${API}/slider/list`,

    //Notification

    getNotificationById: `${API}/notification/getById`,
    getNotificationId: `${API}/notification/getNotificationId`,

    //college

    getCollege: `${API}/college/list`,
    getCollegeData: `${API}/college/get`,
    getMember: `${API}/member/list`,

    //college

    getContact: `${API}/contact/list`,

    //student

    getStd: `${API}/student/getStudent`,
    getStudentByCourse: `${API}/student/getStudentByCourse`,
    createStd: `${API}/student/create`,
    deleteDp:`${API}/student/deleteDp`,
    getStudentById:`${API}/student/getStudentById`,
    block:`${API}/student/block`,
    deleteStd:`${API}/student/delete`,
    changeVerifyStudent:`${API}/student/changeVerify`,

    //teacher

    getTeacher: `${API}/teacher/getTeacher`,
    getClassStudents: `${API}/teacher/getClassStudents`,
    getTeacherByCourse: `${API}/teacher/getTeacherByCourse`,
    createTeacher: `${API}/teacher/create`,
    deleteTeacherDp:`${API}/teacher/deleteDp`,
    blockTeacher:`${API}/teacher/block`,
    changeVerifyTeacher:`${API}/teacher/changeVerify`,
    deleteTeacher:`${API}/teacher/delete`,
    getTeacherById:`${API}/teacher/getById`,
    getTeachersList:`${API}/teacher/list`,

    //hod

    gethod: `${API}/hod/getHod`,
    getCourseTeacher: `${API}/hod/getCourseTeacher`,
    gethodByCourse: `${API}/hod/getHodByCourse`,
    createhod: `${API}/hod/create`,
    deletehodDp:`${API}/hod/deleteDp`,
    getHodList:`${API}/hod/list`,

    // image
    uploadImg: `${API}/image/uploadImg`,
    getImgUrl: `${API}/image/getImg`,
    upload: `${API}/image/upload`,
    getAllPhotos: `${API}/image/getAllPhotos`,

    // topper

    topper: `${API}/topper/topper`,
    topperSlide: `${API}/topper/topperSlider`,
    topperByDept: `${API}/topper/topperByDept`,



}

export { Axios, routes, API, ROOT_URL, IMG_URL, ADMIN_PANEL,PROFILEPIC };
