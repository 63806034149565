import { Route, Routes } from 'react-router-dom'
import Home from './home'
import Courses from './course'
import Syllabus from './syllabus'
import Header from '../header'
import Sidebar from '../sidebar'
import NewsBanner from '../newsBanner'
import styles from './layout.module.scss'
import Footer from '../footer'
import Fees from './fees/page'
import Contacts from './contacts/page'
import About from './about/page'
import RootDepartment from './departments'
import Student from './student'
import PrivateRoute from '../privateRoute'
import Staff, { HodRoute } from '../staffRoute'
import Teacher from './teacher'
import Hod from './hod'
import News from './news'
import Galary from './galary'
import Feature from './feature'
import Topper from './topper'
import Notification from './notification'
const Root = () => {
    return (
        <div>
            <div className={styles.header}>
            <Header />
            </div>
            <div className={styles.container}>
                <div className={styles.sidebar}>
                    <Sidebar />
                </div>
                <div className={styles.main}>
                    <div className={styles.news}>
                        <NewsBanner />
                    </div>
                    <div className={styles.page}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/course" element={<Courses />} />
                        <Route path="/syllabus" element={<Syllabus />} />
                        <Route path="/fees" element={<Fees />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/departments/*" element={<RootDepartment />} />
                        <Route path="/galary/*" element={<Galary />} />
                        <Route path="/feature/*" element={<Feature />} />
                        <Route path="/topper" element={<Topper />} />
                        <Route path="/news/:id" element={<News />} />
                        <Route path="/notification/:id" element={<Notification />} />
                        <Route element={<PrivateRoute />}>
                            <Route path='/student' element={<Student />} />
                        </Route>
                        <Route element={<Staff/>}>
                            <Route path='/teacher' element={<Teacher />} />
                        </Route>
                        <Route element={<HodRoute/>}>
                            <Route path='/hod' element={<Hod />} />
                        </Route>
                    </Routes>
                    </div>

                    <div>
                        <div className={styles.footer}>
                            {/* <Footer /> */}

                        </div>

                    </div>
                </div>
            </div>
            <Footer className={styles.footer} />

        </div>
    )
}

export default Root