import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Axios, routes } from '../../../config';
import Loader from '../../../loader';
import { error } from '../../../services/error';
import { Toast } from '../../../services/error/notification';
import styles from './index.module.scss'
export class VerifyEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            loading: false,
            verification: false,
            otp: ""
        }
    }

    takeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }


    resendOtp = (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        const { email } = this.state;
        const data = { email }

        Axios.post(routes.resendOtp, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                    );
                    this.setState({verification:true})
                    return;
                }
            }).catch(err => {
                error(err);
                return console.log(err)
            }).finally(() => {
                this.setState({
                    loading: false,
                    otp: "",
                });
                return;
            })
    }

    toggleVerify(e) {
        e.preventDefault();

        this.setState({ loading: true });
        const {
            email, otp
        } = this.state;

        const data = { email, otp }


        Axios.post(routes.verifyOtp, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                    );
                    window.location.href = '/auth/login'
                    return;
                }
            }).catch(err => {
                error(err);
                return console.log(err)
            }).finally(() => {
                this.setState({
                    loading: false,
                    otp: "",
                });
                return;
            })
    }

    render() {
        const { verification ,email} = this.state;

        if (this.state.loading) {
            return <Loader />
        }

        return (
            <div className='loginPopup'>

                {!verification ? <div className='login-page'>
                    <form className="form" onSubmit={(e) => this.resendOtp(e)} >
                        <div className={styles.otp}>Email</div>
                        <input type="email" minLength={3} maxLength={50} placeholder='email' value={email} required name='email' onChange={(e) => { this.takeInput(e) }} />
                        <button type='submit' >Send OTP</button>
                        <p className='home'><Link to={'/'} >Go to Home Page</Link> </p>
                    </form>

                </div> :
                    <div className='login-page'>
                        <form className="form" onSubmit={(e) => this.toggleVerify(e)} >
                            <div className={styles.otp}>verify Otp send on your email</div>
                            <input type="email" disabled={verification} minLength={3} maxLength={50} value={email} required name='email' onChange={(e) => { this.takeInput(e) }} />
                            <input type="text" minLength={3} maxLength={10} required name='otp' onChange={(e) => { this.takeInput(e) }} />
                            <button type='submit' >Verify OTP</button>
                            <p onClick={()=>{this.setState({verification:false})}} className={styles.resend}>Edit Email</p>
                        </form>

                    </div>
                }

            </div>
        )
    }
}

export default VerifyEmail