import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './index.module.scss'
import { IMG_URL, PROFILEPIC } from '../config';
const CommentSlider = ({comments}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false

  };

  return (
    <div className={styles.xcarousel}>

    <Slider {...settings}>
      {comments.map((comment) => (
        <div key={comment.id} className={styles.slider} >
        <div className={styles.comment}>
          {comment.user?.student?.image?<img src={IMG_URL + comment.user.student.image} alt="" />:<img src={PROFILEPIC} alt="profilepic" />}
          <div className={styles.name}>{comment.user.name}{comment.user.lastname && ' ' + comment.user.lastname}</div>
        <p>{comment.content}</p>
        </div>
      </div>
      ))}
    </Slider>

    </div>
  );
};

export default CommentSlider;
