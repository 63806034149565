import React, { useEffect, useState } from 'react'
import { deleteCookie, getCookie } from '../../services/cookie'
import Select from 'react-select'
import styles from './index.module.scss'
import { Axios, IMG_URL, PROFILEPIC, routes } from '../../config'
import { error } from '../../services/error'
import Loader from '../../loader'
import { imageApi } from '../../services/image'
import { Toast } from '../../services/error/notification'
import DeleteComment from '../../deleteComment'
const Array = (data, label, value) => {
  const arrayItems = [];
  if (data) {
    data.map(items => {
      return (
        arrayItems.push({ label: (items)[label], value: (items)[value] })
      )
    })
  }
  return arrayItems;
}

const Student = () => {

  const [Profile, setProfile] = useState({
   departmentId: null, courseId: null, year: null, userId: null, image: "", name: "", lastname: "",phone:null
  })

  const [state, setState] = useState({ photo: "", ext: "", loading: true, hideBtn: false, hide: true ,load:false})

  const [data, setData] = useState({
    department: [], courses: [], year: []
  })

  const [student, setStudent] = useState({});


  const getStd = () => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.get(routes.getStd, {
      headers: {
        "Authorization": `Bearer ${getCookie('token')}`
      }
    })
      .then(result => {
        if (result.data) {
          let d = result.data?.list;
          setState((prev) => ({ ...prev, hideBtn: false }))
          setStudent(d);
          setProfile((prev) => ({ ...prev, image: d?.student?.image, name: d?.name, lastname: d?.lastName,phone:d?.phone }))
          return;
        }
        setState((prev) => ({ ...prev, hideBtn: true }))
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }



  const getDep = () => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.get(routes.getdepartment)
      .then(result => {
        if (result.data) {
          setData((prev) => ({ ...prev, department: result.data.list }))
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  const getCourseByDep = (id) => {
    Axios.get(`${routes.getcourseByDepId}?id=${id}`)
      .then(result => {
        if (result.data) {
          setData((prev) => ({ ...prev, courses: result.data.list }));
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  useEffect(() => {
    getDep();
    getStd();
    window.scrollTo(0,0);
    return;
  }, [])




  const logout = () => {
    deleteCookie("token");
    window.location.reload()
  }


  const onSelectDepartment = (e) => {
    setProfile((prev) => ({ ...prev, departmentId: e.value }));
    getCourseByDep(e.value);
    return;
  }


  const onSelectCourse = (e) => {
    setProfile((prev) => ({ ...prev, courseId: e.value }));
    let course = data.courses.filter((d) => {
      return d.id = e.value
    })
    handleYear(course[0].duration);
    return;
  }

  const onSelectYear = (e) => {
    setProfile((prev) => ({ ...prev, year: e.value }))
    return;
  }

  const handleOnChange = (e) => {
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    return;
  }


  const handleYear = (y) => {
    const year = [];

    for (let i = 0; i < y; i++) {
      year.push({ name: i + 1, id: i + 1 })
    }
    setData((prev) => ({ ...prev, year }))
  }


  const handleFileChange = (e) => {
    const fileName = e.target.files[0]?.name;
    const fileExtension = fileName.split('.').pop();
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 1MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && file.size > maxSizeInBytes) {
      alert('Image size exceeds the limit (2MB). Please choose a smaller image.');
      // Clear the file input if needed
      e.target.value = '';
      setState(prev => ({
        ...prev, [e.target.name]: null, ext: null
      }))
    }
    else if (!allowedTypes.includes(file.type)) {
      alert('Invalid file type. Please choose a JPEG, PNG, or GIF image.');
      e.target.value = '';
      setState(prev => ({
        ...prev, [e.target.name]: null, ext: null
      }))
    }
     else {
      setState(prev => ({
        ...prev, [e.target.name]: file, ext: fileExtension
      }))
    }

  }

  const upload = () => {
    setState((prev) => ({ ...prev, loading: true }))

    let { photo, ext } = state;

    let { image } = Profile;

    if (photo) {
      const key = `kuchaman/student/${Date.now()}.${ext}`
      let d = { prevKey: image, type: ext,key }
      imageApi.deleteFile(d)
        .then((success) => {
          if (success) {
            setProfile((prev) => ({ ...prev, image: success.data.key }))
            imageApi.UploadToS3(success.data.url, photo)
              .then((r) => {

                Axios.post(routes.createStd, { image: success.data.key }, {
                  headers: {
                    "Authorization": `Bearer ${getCookie('token')}`
                  }
                })
                  .then(result => {
                    if (result.data) {
                      Toast(
                        "success!!",
                        "photo uploaded successfully",
                        "success"
                      );
                      hideUpload()
                      return;
                    }
                    return [];
                  }).catch((err) => {
                    error(err)
                  })
                setState((prev) => ({ ...prev, loading: false, photo: null }))
                return;

              }).catch(err => console.error(err))
            return
          }
          setState((prev) => ({ ...prev, loading: false, photo: null }))
          return;
        }).catch(err => console.error(err))
    }

  }

  const deleteDp = (id, key) => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.delete(`${routes.deleteDp}?id=${id}&&key=${key}`, {
      headers: {
        "Authorization": `Bearer ${getCookie('token')}`
      }
    })
      .then(r => {
        if (r.data) {
          getStd();
          hideUpload();
          Toast(
            "Success!!", r.data?.mess, "success"
          )
          return;
        }
      }).catch(err => {
        console.error(err)
        return error(err)
      }).finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
        return;
      })
  }

  const create = (e) => {
    e.preventDefault()
    const { departmentId, courseId, year, name, lastname ,phone} = Profile;
  
    setState((prev) => ({ ...prev, loading: true }));
    let data = { departmentId, courseId, year, name, lastname,phone};

    Axios.post(routes.createStd, data, {
      headers: {
        "Authorization": `Bearer ${getCookie('token')}`
      }
    })
      .then(result => {
        if (result.data) {
          getStd();
          setState((prev) => ({ ...prev, hideBtn: false }))
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  const handleCancel = () => {
    setState((prev) => ({ ...prev, hideBtn: !prev.hideBtn }))
  }

  const hideUpload = () => {
    setState((prev) => ({ ...prev, hide: !prev.hide }))
  }


  const { image, name, lastname,phone } = Profile;
  const { loading, hideBtn, photo, hide} = state;

  if (loading) {
    return <Loader />
  }

  return (
    <main className={styles.home}>
      {hideBtn ?
        <div className={styles.create}>
          {<form className={styles.addProduct} onSubmit={create}>
            <div className={styles.head}>
              {<h2>Update Profile</h2>}
            </div>

            <div className={styles.productItems}>

              <div>
                <label htmlFor="name">Name</label>
                <input type="text" maxLength={20} placeholder='name' value={name} name='name' onChange={(e) => { handleOnChange(e) }} />
              </div>
              <div>
                <label htmlFor="lastname">lastname</label>
                <input type="text"  maxLength={20} placeholder='jatt' value={lastname} name='lastname' onChange={(e) => { handleOnChange(e) }} />
              </div>
             
              <div>
                <label htmlFor="phone">Mobile</label>
                <input  type="tel" minLength={10}  maxLength={10} placeholder='7568445341' value={phone} name='phone' onChange={(e) => { handleOnChange(e) }} />
              </div>
             
              <div>
                <label htmlFor="">Department *</label>
                <Select className={styles.select} options={Array(data.department, "name", "id")} onChange={(e) => { onSelectDepartment(e) }} />
              </div>
              <div>
                <label htmlFor="">Course *</label>
                <Select className={styles.select} options={Array(data.courses, "name", "id")} onChange={(e) => { onSelectCourse(e) }} />
              </div>

              <div>
                <label htmlFor="">Current Year *</label>
                <Select className={styles.select} options={Array(data.year, "name", "id")} onChange={(e) => { onSelectYear(e) }} />
              </div>

            </div>

            <div className={styles.btn}>
              
                <button type='submit' >Update Profile</button>
                <div onClick={handleCancel} >Cancel</div>
                
            </div>
          </form>}
        </div> :

        <div className={styles.student}>
          {
            hide ? <div className={styles.profile}>
              {image?<img src={IMG_URL + image} alt="studentpic" />:<img src={PROFILEPIC} alt="profilepic" />}
              <div>
                <button onClick={() => { hideUpload() }}>{image ? "Change Profile" : "Add Profile Photo"}</button>
                <button onClick={handleCancel}>Update Information</button>
                <button onClick={logout}>Logout</button>
              </div>
            </div>

              : <div className={styles.upload}>
                <div>
                  <label htmlFor="">Profile Photo*</label>
                  <input type="file" accept="image/jpeg,image/png,image/gif" name='photo' onChange={(e) => { handleFileChange(e) }} />
                </div>
                <div>
                  <button onClick={upload} disabled={photo ? false : true} >upload</button>
                  <button onClick={() => { hideUpload() }}>Cancel</button>

                </div>
                <div>
                  <button disabled={!image && true} onClick={() => { deleteDp(student?.student?.id, image) }}>Delete Profile</button>
                </div>

              </div>

          }

          <div className={styles.info}>
            <div>
              <label>Name: </label>
              {student?.name} {student?.lastName}
            </div>
            <div>
              <label>Email: </label>
              {student?.email}
            </div>

            <div>
              <label>Mobile: </label>
              {student?.phone || '-'}
            </div>

            <div>
              <label>Department Name: </label>
              {student?.student?.department?.name || '-'}
            </div>

            <div>
              <label>Course Name: </label>
              {student?.student?.course?.name || '-'}
            </div>

            <div>
              <label>Year: </label>
              {student?.student?.year || '-'}
            </div>
            <div>
              <label>Verified Student: </label>
              {student?.verify?"Verified":"Not Verified" || '-'}
            </div>

          </div>

          <DeleteComment/>
       

        </div>
      }

    </main>
  )
}

export default Student;