import { Link, useLocation } from 'react-router-dom'
import styles from './page.module.scss'
import { useEffect, useState } from 'react';
import { Axios, ROOT_URL, routes } from '../../config';
import { error } from '../../services/error';
import Loader from '../../loader';
export function Navigation({style,text}) {
  const path=useLocation()
  let pathname=path.pathname;
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const getList=()=>{
     Axios.get(`${routes.getdepartment}`).then((result) => {
      if (result) {  
        setData(result.data.list)
        return;
      }
      return;
    }).catch((err) => {
        error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
   getList();
  }, []);

  if (loading) {
    return <Loader/>
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.info}>
      {text}
      </div>
    <div className={styles.nav} style={style}>
      {
        data?.map(d=>{
          return( <Link className={pathname === `${ROOT_URL}/departments/${d.name}` ? styles.active : styles.inactive} to={`${ROOT_URL}/departments/${d.name}`}>{d.name}</Link>)
        })
      }
        </div>
    </div>
  )
}