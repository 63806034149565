import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import Sidebar from '../sidebar'
import { Axios, IMG_URL, ROOT_URL, routes } from '../config'
import { Link } from 'react-router-dom'
import { GiHamburgerMenu } from "react-icons/gi";
import { error } from '../services/error'
import Loader from '../loader'
const Header = () => {

    const [sidebar, setSidebar] = useState(true);
    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({
        name: "",
        city: "",
        university: "",
        universityLogo: "",
        collegeLogo: ""

    })
    const hide = () => {
        setSidebar(!sidebar);
        return;
    }

    useEffect(() => {

        Axios.get(routes.getCollege)
            .then((r) => {
                if (r.data.list) {
                    setData(r.data.list)
                }
            }).catch(err => {
                console.error(err);
                error(err)
            }).finally(() => {
                return setLoading(false)
            });

    }, [])

    if (loading) {
        return <Loader />
    }

    return (

        <div className={styles.header}>
            <div className={`${styles.leftbar} ${sidebar && styles.hide}`}>
                <div className={styles.back} onClick={hide} />
                <div className={styles.sidebar}>
                    <Sidebar hidden={hide} />
                </div>
            </div>


            <Link to={ROOT_URL} className={styles.logo}>
                <img src={IMG_URL + data.collegeLogo} width={65} height={65} alt='kuchaman' />
            </Link>

            <div className={styles.name}>
                <div className={styles.college}>
                    {data?.name} , {data.city} city
                </div>
                <div className={styles.univercity}>
                    Affiliated to {data.university}
                </div>
            </div>

            <div className={styles.logo2}>
                <img src={IMG_URL + data.universityLogo} width={65} height={65} alt='kuchaman' />
            </div>

            <div className={styles.hamburger} onClick={hide}>
                <GiHamburgerMenu size={"2rem"} />
            </div>

        </div>

    )
}

export default Header;