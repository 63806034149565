import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { IoIosArrowDropright } from "react-icons/io";
import { MdLocationOn,MdEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa6";
import { BsFacebook ,BsTwitter} from "react-icons/bs";
import { RxDoubleArrowUp } from "react-icons/rx";
import { FaInstagram,FaYoutube,FaLinkedinIn } from "react-icons/fa";
import {Link} from 'react-router-dom'
import { Axios, ROOT_URL, routes } from '../config';
import { error } from '../services/error';
import Loader from '../loader';
const Footer= () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
 function goTop(){
    window.scrollTo(0,0)
  }

  function getData(){
    setLoading(true)
    Axios.get(routes.getCollegeData)
    .then(r=>{
      if (r?.data?.list) {
        setData(r.data.list);
        return;
      }
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

useEffect(() => {
  getData()
}, [])

if (loading) {
  return <Loader/>
}

  return (
    <div className={styles.footer}>
      <div className={styles.foot}>
      <div className={styles.section}>
          <div className={styles.name}>About Us</div>
          <div className="styles">
          <p>  Quality Education is our forever objective. We believe that quality education is the birthright of every student. </p>
            </div>
          <div className={styles.social}>
            <Link to={"/#"} onClick={()=>{window.scrollTo(0,0);}}><BsFacebook  size={"1.5rem"}/></Link>
            <Link to={"/"}><FaInstagram size={"1.5rem"} /></Link>
            <Link to={"#"}><BsTwitter  size={"1.5rem"}/></Link>
            <Link to={"#"}><FaYoutube size={"1.5rem"} /></Link>
            <Link to={"#"}><FaLinkedinIn  size={"1.5rem"}/></Link>
          </div>
        </div>

        <div className={styles.section}>
        <div className={styles.name}>quik links</div>
        <div className={styles.links}>
          <Link to={ROOT_URL+'/'} className={styles.link} ><IoIosArrowDropright className={styles.name} /> Home</Link>
          <Link to={ROOT_URL+'/about'} className={styles.link}> <IoIosArrowDropright className={styles.name} />About Us</Link>
          <Link to={ROOT_URL+'/galary'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Gallary</Link>
          <Link to={ROOT_URL+'/feature'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Features</Link>
          <Link to={ROOT_URL+'/contacts'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Contact Us</Link>
          <Link to={ROOT_URL+'/topper'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Toppers</Link>
          <Link to={ROOT_URL+'/course'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Courses</Link>
          <Link to={ROOT_URL+'/fees'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Fees</Link>
          <Link to={ROOT_URL+'/syllabus'} className={styles.link}> <IoIosArrowDropright className={styles.name} />Syllabus</Link>
        </div>
        </div>

        <div className={styles.section}>
        <div className={styles.name}>location</div>
        <div className={styles.locations}>
          <div className={styles.location}><MdLocationOn className={styles.name} />{data?.name}{data?.city&& ' '+data?.city +' city'}</div>
          <div className={styles.location}> <MdEmail className={styles.name} />{data?.email}</div>
          <div className={styles.location}><FaPhoneVolume className={styles.name} />{data?.phone}</div>
        </div>
          
        </div>
        <div className={styles.section}>
        <div className={styles.map}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3550.661326476623!2d74.85027757455163!3d27.135475276515038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396b78e1f6dc4bd1%3A0x5b0f9d830fe3bc6c!2sKuchaman%20College!5e0!3m2!1sen!2sin!4v1716707873678!5m2!1sen!2sin" width="300" height="300" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
          
        </div>
      </div>
        
        <div className={styles.copyrite}>
          <div>Powered by Prakashweb</div>
          <div>© 2024 {data?.name}{data?.city&& ' '+data?.city +' city'} All Rights Reserved.</div>
          <RxDoubleArrowUp className={styles.top} onClick={goTop}  size={"2rem"}/>
        </div>

    </div>
  )
}

export default Footer