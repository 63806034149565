import { ReactNotifications } from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";
const Notify = () => {
  return (
    <div>
        <ReactNotifications/>
    </div>
  )
}

export default Notify