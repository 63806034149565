import React, { useEffect, useState } from 'react'
import styles from './page.module.scss'
import { IMG_URL, routes } from '../../config'
import { error } from '../../services/error';
import Loader from '../../loader';
const About =  () => {

    const [data, setdata] = useState([]);
    const [member, setMember] = useState([])
    const [load, setLoad] = useState(false)

    const getData = async() => {
        setLoad(true)
        fetch(routes.getCollege,{cache:"no-store"})
        .then(async(result) => {
            if (result.ok) {
              let res=await result.json()
              setdata(res.list)
              return;
            }
            return [];
          }).catch((err) => {
              error(err)
          }).finally(()=>{
            return setLoad(false)
          })
    }

    const getMember = async() => {
        setLoad(true)
        fetch(routes.getMember,{cache:"no-store"}).then(async(result) => {
            if (result.ok) {
              let res=await result.json()
              setMember(res.list)
              return;
            }
            return [];
          }).catch((err) => {
              error(err)
          }).finally(()=>{
            return setLoad(false)
          })
    }

    useEffect(() => {
      getData()
    getMember()
    window.scrollTo(0,0);
    }, [])
    
    if (load) {
        return <Loader/>
    }

    return (
        <div className={styles.about}>

            <div className={styles.member}>
                {
                    member?.map((m) => {
                        return (
                            <div key={m.id}>
                                <img src={IMG_URL+m?.image} alt={m.name} />
                                <span>{m.name}</span>
                                <span>{m.post}</span>
                            </div>
                        )
                    })
                }


            </div>

            <div className={styles.simity}>
                <div className={styles.photo}>
                    <img src={IMG_URL+data?.simityPhoto} alt="simity" />
                </div>

                <div className={styles.content}>
                    {data.about}
                </div>
            </div>

        </div>
    )
}

export default About