import React, { useLayoutEffect } from 'react'
import styles from './page.module.scss'
import { Navigation } from './navigation';
import { Route, Routes } from 'react-router-dom';
import Dep from './department';

const RootDepartment = () => {
  let style={
    flexDirection:"column" ,width:"30vw" ,alignItems:"center",marginTop:"2rem",marginLeft:"30vw",backgroundColor:"green",padding:"2rem 7rem"
  }
  useLayoutEffect(() => {
    window.scrollTo(0,0);
  }, [])
  return (
    <div className={styles.root} style={{marginTop:""}}>
        <Navigation/>
        <Routes>
        {/* <Route path="/" element={<Navigation style={style} text={"Please clink on any department"}/>} /> */}
        <Route path="/:name" element={<Dep/>} />
        </Routes>
    </div>
  )
}

export default RootDepartment;