import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './login'
import Register from './register'
import ForgetPassword from './forgetPassword'
import VerifyEmail from './verifyEmail'

export class Auth extends Component {
    // constructor(props){
    //     super(props);
    // }
  render() { 
    return (
      <div>
        <Routes>
            <Route path="/login" element={<Login/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/forget-password' element={<ForgetPassword/>}/>
            <Route path="/verify-email" element={<VerifyEmail />} />

        </Routes>
      </div>
    )
  }
}

export default Auth