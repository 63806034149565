import React from 'react'
import { Route, Routes } from 'react-router-dom'
import List from './list'
import Category from './category'

const Feature = () => {
  return (
    <div>
    <Routes>
        <Route path='/' element={<List/>}/>
        <Route path='/:id' element={<Category/>}/>
    </Routes>

    </div>
  )
}

export default Feature