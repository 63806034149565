import React, { useState } from 'react'
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
import { ADMIN_PANEL, ROOT_URL } from '../config'
import { BiSolidLeftArrowSquare, BiSolidRightArrowSquare } from "react-icons/bi";
import { ImCross } from "react-icons/im";
const Sidebar = ({ hidden }) => {
    const [hide, setHide] = useState(true);

    const onHide = () => {
        setHide((prev) => (!prev))
    }

    const cancel = () => {
        hidden()
    }

    return (
        <div className={styles.sidebar}>
            {hide ?
                <div className={styles.bar}>
                    {!hidden && <div className={styles.route}>
                        <div onClick={onHide} className='pointer'><BiSolidLeftArrowSquare size={"2rem"} /></div>
                    </div>}
                    {hidden && <div className={styles.route}>
                        <div onClick={cancel} className='pointer'><ImCross size={"2rem"} /></div>
                    </div>}

                    <div className={styles.route}>
                        <Link to={`${ROOT_URL}/`} onClick={hidden} >Home</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/departments/dep`} >Departments</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/feature`} >Features</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/galary`} >Galary</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/topper`} >Topper</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/about`} >About Us</Link>
                    </div>
                    <div className={styles.route}>
                        <Link to={`${ROOT_URL}/course`} onClick={hidden} >Courses</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/fees`} >Fees</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/syllabus`} >Syllabus</Link>
                    </div>

                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/contacts`} >Contacts Us</Link>
                    </div>
{/* 
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/student`} >Student Panel</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/teacher`} >Teacher Panel</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ROOT_URL}/hod`} >HOD Panel</Link>
                    </div>
                    <div className={styles.route} onClick={hidden}>
                        <Link to={`${ADMIN_PANEL}`} target='_blank' >College Panel</Link>
                    </div> */}
                </div>

                : <div className="pointer" onClick={setHide}>
                    <BiSolidRightArrowSquare size={"2rem"} />
                </div>}


        </div>
    )
}

export default Sidebar