import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import './index.css'
import { Axios, routes } from '../../../config';
import { error } from '../../../services/error';
import Loader from '../../../loader';
import { Toast } from '../../../services/error/notification';
import { setCookie } from '../../../services/cookie';
import {BiSolidHide} from 'react-icons/bi'
import {FaEye} from 'react-icons/fa'
export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            loading: false,
            passwordHide:true
        }
    }

    seePassword = () => {
            const { passwordHide } = this.state;
            this.setState({ passwordHide: !passwordHide })
            return
    }

    takeInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    toggleLogin(e) {
        e.preventDefault();
        this.setState({ loading: true })
        const {
            email, password
        } = this.state;

        const data = { email, password }

        Axios.post(routes.login, data)
            .then(success => {
                if (success.data) {

                    if (success.data.role === "Student") {
                        setCookie('token', success.data.token, 1);

                        Toast(
                            "Success !!", success.data.mess, "success"
                        )
                        window.location.href = "/student"

                    } else if (success.data.role === "Teacher") {

                        setCookie('xtoken', success.data.token, 1);

                        Toast(
                            "Success !!", success.data.mess, "success"
                        )
                        window.location.href = "/teacher"

                    } else if (success.data.role === "HOD") {

                        setCookie('htoken', success.data.token, 1);

                        Toast(
                            "Success !!", success.data.mess, "success"
                        )
                        window.location.href = "/hod"

                    }

                    return;
                }
            }).catch(err => {
                error(err);
                return
            }).finally(() => {
                this.setState({ loading: false });
                return;
            })
    }

    render() {
        const{loading,passwordHide}=this.state;
        if (loading) {
            return <Loader />
        }
        return (
            <div className='loginPopup'>

                <div className="login-page">
                    <div className="form">
                        <form className="login-form">
                            <input type="text" placeholder="email" name='email' onChange={(e) => { this.takeInput(e) }} />
                            <div className='seePassword'>
                                <input type={passwordHide&&"password"} placeholder="password" name='password' onChange={(e) => { this.takeInput(e) }} />
                                {passwordHide ? <span className='pointer' onClick={() => { this.seePassword() }}><FaEye size={"1.2rem"} /></span> :
                                    <span className='pointer' onClick={() => { this.seePassword("password") }}><BiSolidHide size={"1.2rem"} /></span>}
                            </div>
                            <button onClick={(e) => this.toggleLogin(e)}>login</button>
                            <p className="message">Not registered? <Link to={'/auth/register'} >Create an account</Link></p>
                            <p className='forget'><Link to={'/auth/forget-password'} >Forget Password</Link> </p>
                            <p className='home'><Link to={'/auth/verify-email'} >Verify Email</Link> </p>
                            <p className='home'><Link to={'/'} >Go to Home Page</Link> </p>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login