import React, { useEffect, useState } from 'react'
import { Axios, IMG_URL, routes } from '../../../config';
import { error } from '../../../services/error';
import Loader from '../../../loader';
import styles from './index.module.scss'
import { Link } from 'react-router-dom'
const List = ({ limit }) => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  const getList = () => {
    setLoading(true);
    setData([])
    Axios.get(`${routes.getfeature}?limit=${limit}`).then((result) => {
      if (result) {
        setData(result.data.list);
        setLoading(false)
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    getList();
    window.scrollTo(0, 0)
  }, [])


  if (loading) {
    return <Loader />
  }

  return (
    <div className={styles.list}>
      {data?.length > 0 && <h1>Features</h1>}

      <div className={styles.container}>
        {
          data?.map(d => {
            return (
              d.images.length===1&&  <Link to={`/feature/${d.id}`} key={d.id} className={styles.album}>
                <div className={styles.photo}>
                  <div className={styles.layer}>
                    <div className={styles.layer}>
                      <div className={styles.layer}>
                        <img src={IMG_URL + d.images[0]?.photo} alt="galary" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.category}>
                  {d.name}
                </div>
                {/* <div className={styles.count}>
                  {d.imageCount} Photo
                </div> */}
              </Link>
            )
          })
        }

      </div>

    </div>
  )
}

export default List