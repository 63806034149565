import React, { useState, useEffect } from 'react';
import { Axios,routes } from '../config';
import { Toast } from '../services/error/notification';
import { error } from '../services/error';
import styles from './index.module.scss'
import Loading from '../loading';
import CommentSlider from '../commentSlider';
const Comments = () => {
  const [comments, setComments] = useState([]);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchComments(limit);
  }, [limit]);

  const fetchComments = async (limit) => {
    try {
      setLoading(true)
      const response = await Axios.get(`${routes.getComments}?limit=${limit}`);

      if (response.data.list) {
        setComments(response.data.list);
        return
      } else {
        Toast('Empty', response.data.mess, 'info')
        return;
      }

    } catch (err) {
      return error(err)
    } finally {
      return setLoading(false)
    }
  };



  if (loading) {
    return <Loading />
  }

  return (
    <div className={styles['comments-container']}>
     {comments?.length>0&& <>
      <h1>Students Reviews</h1>

      <CommentSlider comments={comments} />


      <button className={styles.button} onClick={() => { setLimit(limit + 10) }}>See More Reviews</button>
      </>}

    </div>
  );
};

export default Comments;
