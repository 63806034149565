import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './index.module.scss'
import { IMG_URL } from '../config';
const MyCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false
  };

  return (
    <div className={styles.carousel}>

      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className={styles.slider}>
            <img src={IMG_URL + image?.image} alt={`galary ${index}`} />
          </div>
        ))}
      </Slider>

    </div>
  );
};

export default MyCarousel;
