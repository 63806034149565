import { Navigate, Outlet } from 'react-router-dom'
import { getCookie } from '../services/cookie';
const Staff = () => {
  let token=getCookie("xtoken");

  let auth = {'xtoken':token}
return (
    auth.xtoken ? <Outlet/> : <Navigate to='/auth/login'/>
  )
}

export const  HodRoute = () => {
  let token=getCookie("htoken");

  let auth = {'htoken':token}
return (
    auth.htoken ? <Outlet/> : <Navigate to='/auth/login'/>
  )
}


export default Staff


