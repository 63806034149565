import React, { useEffect, useState } from 'react'
import { Axios, IMG_URL, routes } from '../../../config';
import { error } from '../../../services/error';
import Loader from '../../../loader';
import { useParams } from 'react-router-dom';
import styles from './index.module.scss'
import parse from "html-react-parser";
import 'react-quill/dist/quill.snow.css';

const Category = () => {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)


  const params = useParams();

  const getList = (id) => {
    setLoading(true);
    setData([])
    Axios.get(`${routes.getgalaryById}?id=${id}`).then((result) => {
      if (result) {
        setData(result.data.list);
        setLoading(false)
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    const id = params.id;
    getList(id)
    window.scrollTo(0,0)
  }, [params.id])


  if (loading) {
    return <Loader />
  }

  return (
    <div className={styles.galary}>

      <h1>{data?.category}</h1>

      {data?.desc && <div className={styles.desc}>
        <div className="ql-editor">
          {
            parse("" + data?.desc)
          }
        </div>
      </div>}

      <div className={styles.container}>

        {
          data?.images?.map(d => {
            return (
              <div to={`/galary/${d.id}`} key={d.id} className={styles.album}>
                <div className={styles.photo}>
                  <img src={IMG_URL + d.photo} alt="galary" />
                </div>

              </div>
            )
          })
        }
      </div>

    </div>
  )
}

export default Category