import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Axios, ROOT_URL, routes } from '../config';
import Loader from '../loader';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee'
const NewsBanner = () => {

  const [News, setNews] = useState([])
  const [loading, setLoading] = useState(false)

  const getNewsList = () => {
    setLoading(true);
    Axios.get(`${routes.getNewsId}?limit=1`).then((result) => {
      if (result) {
        setNews(result.data.list[0]);
        return;
      }
      return;
    }).catch((err) => {
      console.error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    getNewsList()
  }, [])
  
  if (loading) {
    return <Loader/>
  }

  return (
    <div className={styles.banner}>
      <div >
      <Marquee  pauseOnHover direction="left">
      <Link to={`${ROOT_URL}/news/${News?.id}`}>{News?.title}</Link>
      </Marquee>

      </div>
    </div>
  )
}

export default NewsBanner

