import { Navigate, Outlet } from 'react-router-dom'
import { getCookie } from '../services/cookie'
const PrivateRoutes = () => {
  let token=getCookie("token");
  let auth = {'token':token}
return (
    auth.token ? <Outlet/> : <Navigate to='/auth/login'/>
  )
}


export default PrivateRoutes


