import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Axios, IMG_URL, PROFILEPIC, routes } from '../../config'
import { error } from '../../services/error'
import Loader from '../../loader'
const Topper = () => {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const getList = () => {
        setLoading(true);
        Axios.get(routes.topper)
            .then((d) => {
                if (d.data) {
                    setData(d.data)
                    return;
                }
            }).catch(err => {
                return error(err);
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getList()
    }, [])

    if (loading) {
        return <Loader />
    }
    return (
        <div className={styles.topper}>

            {
                data?.map(d => {
                    return (
                        <div className={styles.courses}>
                            <div className={styles.course}>
                                <h2>{d.course_name} {d.year}</h2>
                            </div>
                            <div className={styles.students}>
                                {d.toppers?.map(t => {
                                    return (
                                        <div className={styles.student}>
                                            {t.photo ? <img src={IMG_URL + t.photo} alt="" /> : <img src={PROFILEPIC} alt="" />}
                                            <div className={styles.name}>
                                                {t.name}
                                            </div>
                                            <div className={styles.father}>
                                                {t?.father && "Child of " + t.father}
                                            </div>
                                            <div className="rank">
                                                {t.rank === 1 ? t.rank + "st" : t.rank === 2 ? t.rank + "nd" : t.rank === 3 ? t.rank + "rd" : t.rank !== null ? t.rank + "th" : ""}  {t?.rank && "Rank"}
                                            </div>
                                            <div className="percent">
                                                {t?.percentage && t?.percentage + "%"}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    )
                })
            }

        </div>
    )
}

export default Topper