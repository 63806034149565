import React, { useEffect, useState } from 'react'
import styles from './page.module.scss'
import { Link } from 'react-router-dom'
import MyCarousel from '../../slider'
import Loader from '../../loader';
import { Axios, IMG_URL, ROOT_URL, routes } from '../../config';
import { error } from '../../services/error';
import Comments from '../../comments';
import Loading from '../../loading';
import Feature from '../feature/list';
import Galary from '../galary/list';
import Marquee from "react-fast-marquee";
// import TopperSlider from '../../topperslider';
const Home = () => {

  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState(null);
  const [member, setMember] = useState([])
  const [slides, setSlides] = useState([])
  // const [topper, setTopper] = useState([])


  const getMember = async () => {
    setLoading(true)
    fetch(routes.getMember, { cache: "no-store" }).then(async (result) => {
      if (result.ok) {
        let res = await result.json()
        setMember(res.list)
        return;
      }
      return [];
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    })
  }

  // const getTopper = () => {
  //   setLoading(true);
  //   Axios.get(`${routes.topperSlide}`).then((result) => {
  //     if (result) {
  //       setTopper(result.data);
  //       return;
  //     }
  //     return;
  //   }).catch((err) => {
  //     error(err)
  //   }).finally(() => {
  //     return setLoading(false)
  //   });
  // }

  const getSlides = () => {
    setLoading(true);
    Axios.get(`${routes.getSlides}`).then((result) => {
      if (result) {
        setSlides(result.data.list);
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  const getNewsList = () => {
    setLoading(true);
    Axios.get(`${routes.getNotificationId}`).then((result) => {
      if (result) {
        setNews(result.data.list);
        return;
      }
      return;
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }


  useEffect(() => {
    getNewsList();
    getSlides();
    getMember();
    // getTopper();
    window.scrollTo(0,0);
  }, [])



  if (loading) {
    return <Loader />
  }
  return (
    <main className={styles.home}>
      <div className={styles.main}>
        {slides?.length > 1 && <div className={styles.slide}>
          <MyCarousel images={slides} />
        </div>}

        {news?.length > 0 && <div className={styles.news}>
          <div className={styles.header}>
            <div>Notifications</div>
          </div>
          
          <div className={styles.content}>
            {news ? 
          //  <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();" className={styles.marq}  pauseOnHover>
            <div className={styles.notification}>
              {
                news.map((n) => {
                  return (
                    <div className={styles.title} key={n.id}>
                      <Link to={`${ROOT_URL}/notification/${n.id}`}>{n.title}</Link>
                    </div>
                  )
                })
              }
            </div>
            // </marquee>
             : <Loading />}
          </div>

        </div>}
      </div>


      <Feature limit={4} />

      <Galary limit={4} />

      <div className={styles.member}>
        {
          member?.map((m) => {
            return (
              <div key={m.id}>
                <img src={IMG_URL + m?.image} alt={m.name} />
                <span>{m.name}</span>
                <span>{m.post}</span>
              </div>
            )
          })
        }
      </div>


     {/* {topper.length>0 && <div className={styles.topper}>
        <h1>Congratulation To Our Toppers</h1>
        <TopperSlider topper={topper} />
      </div>} */}


      <div className={styles.comments}>
        <Comments />
      </div>


    </main>
  )
}

export default Home