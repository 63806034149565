import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Axios, IMG_URL, PROFILEPIC, routes } from '../config';
import { getCookie } from '../services/cookie';
import { Toast } from '../services/error/notification';
import { error } from '../services/error';
import styles from './index.module.scss'
import Loader from '../loader';
import swal from 'sweetalert'
const DeleteComment = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const response = await Axios.get(routes.getCommentById, {
        headers: { Authorization: `Bearer ${getCookie('token')}` }
      });

      if (response.data.list) {
        setComments(response.data.list);
        return
      } else {
        Toast('Empty', response.data.mess, 'info')
        return;
      }

    } catch (err) {
      error(err);
      return
    }
  };

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const words = newComment.trim().split(/\s+/);

      let isValid = true;
      words.forEach(word => {
        if (word.length > 20) {
          isValid = false;
        }
      })
  
      if (words.length > 20) {
        return Toast('Error', "Please limit your comment to 20 words ", 'info')
      }

      if (!isValid) {
        return Toast('Error', "Each word is not more than 20 char", 'info')
      }
  
      setLoading(true);
      const cookie = getCookie('token');
      const create = await axios.post(routes.createComment, { content: newComment }, {
        headers: { Authorization: `Bearer ${cookie}` }
      });

      if (create?.data?.mess) {
        setNewComment('');
        fetchComments();
        Toast('Success', create.data.mess, 'success')
        return;
      }

    } catch (err) {
      return error(err);
    }finally{
      setLoading(false)
      return
    }
  };

  const handleDelete = async (id) => {
    swal({
      title: "Are you sure?",
      text: " you want to Delete the comment",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async(success) => {
        if (success) {
          setLoading(true)
          try {
            const response = await Axios.delete(`${routes.deleteComment}?id=${id}`, {
              headers: { Authorization: `Bearer ${getCookie('token')}` }
            });

            if (response.data.mess) {
              Toast('Deleted', response.data.mess, 'success')
              fetchComments()
              return
            }

          } catch (err) {
            error(err);
            return
          }finally{
            return setLoading(false)
          }
        }
      })
  }

  if (loading) {
    return <Loader/>
  }
  return (
    <div className={styles['comments-container']}>
      <div className={styles['comment-form']}>
        <h2>Students Reviews</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            placeholder="Write your comment..."
            value={newComment}
            onChange={handleCommentChange}
            maxLength={350}
          ></textarea>
          <button type="submit">Add Review</button>
        </form>
      </div>
      <div className={styles.comments}>
        {comments.map((comment, index) => (
          <div key={index} className={styles.comment}>
            <div>
              {comment.user?.student?.image ?<img src={IMG_URL + comment.user.student.image} alt="" />:<img src={PROFILEPIC} alt="profilepic" />}
              <div>{comment.user.name}{comment.user.lastname && ' ' + comment.user.lastname}</div>
            </div>
            <p>{comment.content}</p>
            <button onClick={() => { handleDelete(comment.id) }}>Delete</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DeleteComment;
