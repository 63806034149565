import React, { useEffect, useState } from 'react'
import styles from './page.module.scss';
import { Axios, routes } from '../../config';
import { error } from '../../services/error';
import Loader from '../../loader';
import { MdLocationOn,MdEmail  } from 'react-icons/md'
import { FaPhoneVolume  } from 'react-icons/fa'
const Contacts = () => {

  const [data, setData] = useState([])
  const [contact, setContact] = useState([])
  const [loading, setLoading] = useState(true)

  const getList = () => {
    setLoading(true)
    Axios.get(routes.getContact)
      .then((r) => {
        if (r.data.list) {
          setData(r.data.list)
          return;
        }
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setLoading(false)
      });
  }

  function getData(){
    setLoading(true)
    Axios.get(routes.getCollegeData)
    .then(r=>{
      if (r?.data?.list) {
        setContact(r.data.list);
        return;
      }
    }).catch((err) => {
      error(err)
    }).finally(() => {
      return setLoading(false)
    });
  }

  useEffect(() => {
    getList()
    getData()
    window.scrollTo(0, 0);
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <div className={styles.Contacts}>
      <div className={styles.banner}>
        <span>
          Important Contact list (Call between 10.00 AM - 03.00 PM on working days only)
        </span>
        <span> Important Instructions:- Please see college website for regular updates</span>

      </div>

      <div className={styles.getInTouch}>
        <h3>contact us</h3>
        <h1>Get In Touch With {contact?.name}</h1>
      </div>

      <div className={styles.cards}>
        <div className={styles.card}>
          <MdLocationOn size={"2rem"}/>
          <div>our location</div>
          <div>{contact?.name}{contact?.city&& ' '+contact?.city +' city'}</div>
        </div>
        <div className={styles.card}>
        <FaPhoneVolume size={"2rem"}/>
          <div>Phone Number</div>
          <div>{contact?.phone}</div>
        </div>
        <div className={styles.card}>
        <MdEmail size={"2rem"}/>
          <div>Email Us</div>
          <div>{contact?.email}</div>
        </div>
      </div>

      <div className={styles.details}>
        <table>
          <thead>
            <th>S.No</th>
            <th>Related work</th>
            <th>Contact No</th>
            <th>Email ID</th>
          </thead>

          <tbody>
            {data.map((c, i) => {

              return (<tr key={c.id}>
                <td>{i + 1}</td>
                <td>{c.relatedWork}</td>
                <td><a href={`tel:+${c.number}`}>{c.number}</a> </td>
                <td>{c.email}</td>
              </tr>)
            })
            }
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Contacts;