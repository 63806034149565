import {Triangle } from 'react-loader-spinner';
import styles from './index.module.scss'

function Loader() {

    return (
        <div className={styles.loading}>
          
            <Triangle
                visible={true}
                height="80"
                width="80"
                color="red"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClass=""
            /> 
        </div>

       
    );
}

export default Loader;
