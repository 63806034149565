import React, { useEffect, useState } from 'react'
import { deleteCookie, getCookie } from '../../services/cookie'
import Select from 'react-select'
import styles from './index.module.scss'
import { Axios, IMG_URL, PROFILEPIC, routes } from '../../config'
import { error } from '../../services/error'
import Loader from '../../loader'
import { imageApi } from '../../services/image'
import { Toast } from '../../services/error/notification'
import swal from 'sweetalert'
import Loading from '../../loading'
const Array = (data, label, value) => {
  const arrayItems = [];
  if (data) {
    data.map(items => {
      return (
        arrayItems.push({ label: (items)[label], value: (items)[value] })
      )
    })
  }
  return arrayItems;
}

const ArrayOfStd = (data, label, value) => {
  const arrayItems = [];
  if (data) {
    data.map(items => {
      return (
        arrayItems.push({ label: (items.user)[label], value: (items.user)[value] })
      )
    })
  }
  return arrayItems;
}



const Hod = () => {

  const [Profile, setProfile] = useState({
     qualification: "", experience:"",departmentId: null,phone:"", courseId: null, userId: null, image: "", name: "", lastname: ""
  })

  const [state, setState] = useState({ photo: "", ext: "",load:false, loading: true, hideBtn: false, hide: true, courseId: "" })

  const [data, setData] = useState({
    department: [], courses: []
  })

  const [hod, setHod] = useState([])
  const [select, setSelect] = useState([])

  const [teacher, setTeacher] = useState({});

  const getHod = () => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.get(routes.gethod, {
      headers: {
        "Authorization": `Bearer ${getCookie('htoken')}`
      }
    })
      .then(result => {
        if (result.data) {
          let d = result.data.list;
          setHod(d);
          setProfile((prev) => ({ ...prev, qualification: d?.hod?.qualification,phone:d?.phone, experience: d?.hod?.experience, image: d?.hod?.image, name: d?.name, lastname: d?.lastname }))
          setState((prev) => ({ ...prev, hideBtn: false, courseId: d?.hod?.course?.id}))
          return;
        }
        setState((prev) => ({ ...prev, hideBtn: true }))
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  const getAllteacher = () => {
    setState((prev) => ({ ...prev, load: true }));
    Axios.get(`${routes.getCourseTeacher}`, {
      headers: {
        "Authorization": `Bearer ${getCookie('htoken')}`
      }
    })
      .then(result => {
        if (result.data) {
          let d = result.data?.list?.hod?.department?.teachers;
          setSelect(d)
          setTeacher(d)
          return;
        }
        setState((prev) => ({ ...prev, hideBtn: true }))
        return;
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, load: false }));

      });
  }

  const getDep = () => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.get(routes.getdepartment)
      .then(result => {
        if (result.data) {
          setData((prev) => ({ ...prev, department: result.data.list }))
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  const getCourseByDep = (id) => {
    Axios.get(`${routes.getcourseByDepId}?id=${id}`)
      .then(result => {
        if (result.data) {
          setData((prev) => ({ ...prev, courses: result.data.list }));
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  useEffect(() => {
    getDep();
    getHod();
    getAllteacher();
    window.scrollTo(0,0);
    return;
  }, [])


  const logout = () => {
    deleteCookie("htoken");
    window.location.reload()
  }


  const onSelectDepartment = (e) => {
    setProfile((prev) => ({ ...prev, departmentId: e.value }));
    getCourseByDep(e.value);
    return;
  }


  const onSelectCourse = (e) => {
    setProfile((prev) => ({ ...prev, courseId: e.value }));
    return;
  }


  const handleOnChange = (e) => {
    setProfile((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    return;
  }


  const handleFileChange = (e) => {
  
    const fileName = e.target.files[0]?.name;
    const fileExtension = fileName.split('.').pop();
    const file = e.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 1MB
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (file && file.size > maxSizeInBytes) {
      alert('Image size exceeds the limit (2MB). Please choose a smaller image.');
      // Clear the file input if needed
      e.target.value = '';
      setState(prev => ({
        ...prev, [e.target.name]: null, ext: null
      }))
    }
    else if (!allowedTypes.includes(file.type)) {
      alert('Invalid file type. Please choose a JPEG, PNG, or GIF image.');
      e.target.value = '';
      setState(prev => ({
        ...prev, [e.target.name]: null, ext: null
      }))
    }
     else {
      setState(prev => ({
        ...prev, [e.target.name]: file, ext: fileExtension
      }))
    }


  }
  const upload = () => {
    setState((prev) => ({ ...prev, loading: true }))

    let { photo, ext } = state;

    let { image } = Profile;

    if (photo) {
      const key = `kuchaman/hod/${Date.now()}.${ext}`
      let d = { prevKey: image, type: ext,key }
      imageApi.deleteTeacherPhoto(d,"htoken")
        .then((success) => {
          if (success) {
            setProfile((prev) => ({ ...prev, image: success.data.key }))
            imageApi.UploadToS3(success.data.url, photo)
              .then((r) => {

                Axios.post(routes.createhod, { image: success.data.key }, {
                  headers: {
                    "Authorization": `Bearer ${getCookie('htoken')}`
                  }
                })
                  .then(result => {
                    if (result.data) {
                      Toast(
                        "success!!",
                        "photo uploaded successfully",
                        "success"
                      );
                      hideUpload()
                      return;
                    }
                    return [];
                  }).catch((err) => {
                    error(err)
                  })
                setState((prev) => ({ ...prev, loading: false, photo: null }))
                return;

              }).catch(err => console.error(err))
            return
          }
          setState((prev) => ({ ...prev, loading: false, photo: null }))
          return;
        }).catch(err => console.error(err))
    }

  }

  const deleteDp = (id, key) => {
    setState((prev) => ({ ...prev, loading: true }));
    Axios.delete(`${routes.deletehodDp}?id=${id}&&key=${key}`, {
      headers: {
        "Authorization": `Bearer ${getCookie('htoken')}`
      }
    })
      .then(r => {
        if (r.data) {
          getHod();
          hideUpload();
          Toast(
            "Success!!", r.data?.mess, "success"
          )
          return;
        }
      }).catch(err => {
        console.error(err)
        return error(err)
      }).finally(() => {
        setState((prev) => ({ ...prev, loading: false }));
        return;
      })
  }

  const create = (e) => {
    e.preventDefault()
    const { qualification,experience, departmentId, courseId,  name, lastname,phone } = Profile;

    setState((prev) => ({ ...prev, loading: true }));
    let data = { qualification, departmentId, courseId, name, lastname,experience ,phone};

    Axios.post(routes.createhod, data, {
      headers: {
        "Authorization": `Bearer ${getCookie('htoken')}`
      }
    })
      .then(result => {
        if (result.data) {
          Toast(
            "Success!!", result.data?.mess, "success"
          )
          getHod();
          getAllteacher();
          setState((prev) => ({ ...prev, hideBtn: false }))
          return;
        }
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, loading: false }));

      });
  }

  const handleCancel = () => {
    setState((prev) => ({ ...prev, hideBtn: !prev.hideBtn }))
  }

  const hideUpload = () => {
    setState((prev) => ({ ...prev, hide: !prev.hide }))
  }

  const deleteStd = (id) => {

    swal({
      title: "Are you sure?",
      text: " you want to Delete Teacher",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(success => {
        if (success) {
          setState((prev) => ({ ...prev, load: true }));

          Axios.delete(`${routes.deleteTeacher}?id=${id}`, {
            headers: {
              "Authorization": `Bearer ${getCookie('htoken')}`
            }
          })
            .then(result => {
              if (result.data) {
                let d = result.data.mess;
                getAllteacher()
                Toast(
                  "Success!", d, "success"
                )
                return;
              }
              setState((prev) => ({ ...prev, hideBtn: true }))
              return [];
            }).catch((err) => {
              error(err)
            }).finally(() => {
              return setState((prev) => ({ ...prev, load: false }));

            });
        }
      })


  }

  const verify = (id) => {

    swal({
      title: "Are you sure?",
      text: " you want to update the  Teacher",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(success => {
        if (success) {
          setState((prev) => ({ ...prev, load: true }));

          Axios.delete(`${routes.changeVerifyTeacher}?id=${id}`, {
            headers: {
              "Authorization": `Bearer ${getCookie('htoken')}`
            }
          })
            .then(result => {
              if (result.data) {
                let d = result.data.mess;
                getAllteacher()
                Toast(
                  "Success!", d, "success"
                )
                return;
              }
              setState((prev) => ({ ...prev, hideBtn: true }))
              return [];
            }).catch((err) => {
              error(err)
            }).finally(() => {
              return setState((prev) => ({ ...prev, load: false }));

            });
        }
      })


  }
  
  const block = (id) => {

    swal({
      title: "Are you sure?",
      text: " you want to Block or Unblock the  Teacher",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(success => {
        if (success) {
          setState((prev) => ({ ...prev, load: true }));

          Axios.delete(`${routes.blockTeacher}?id=${id}`, {
            headers: {
              "Authorization": `Bearer ${getCookie('htoken')}`
            }
          })
            .then(result => {
              if (result.data) {
                let d = result.data.mess;
                getAllteacher()
                Toast(
                  "Success!", d, "success"
                )
                return;
              }
              setState((prev) => ({ ...prev, hideBtn: true }))
              return [];
            }).catch((err) => {
              error(err)
            }).finally(() => {
              return setState((prev) => ({ ...prev, load: false }));

            });
        }
      })


  }

  const handleOnSelect = (e) => {
    const id = e.value;

    setState((prev) => ({ ...prev, load: true }));

    Axios.get(`${routes.getTeacherById}?id=${id}`, {
      headers: {
        "Authorization": `Bearer ${getCookie('htoken')}`
      }
    })
      .then(result => {
        if (result.data) {
          let d = result.data.list;
          setTeacher(d)
          return;
        }
        setState((prev) => ({ ...prev, hideBtn: true }))
        return [];
      }).catch((err) => {
        error(err)
      }).finally(() => {
        return setState((prev) => ({ ...prev, load: false }));

      });
  }

  const { qualification,experience, image, name, lastname ,phone} = Profile;
  const { loading, hideBtn, photo, hide,load } = state;

  if (loading) {
    return <Loader />
  }

  return (
    <main className={styles.home}>
      {hideBtn ?
        <div className={styles.create}>
          {<form className={styles.addProduct} onSubmit={create}>
            <div className={styles.head}>
              {<h2>Update Profile</h2>}
            </div>

            <div className={styles.productItems}>

              <div>
                <label htmlFor="name">Name</label>
                <input type="text" maxLength={20} placeholder='name' value={name} name='name' onChange={(e) => { handleOnChange(e) }} />
              </div>
              <div>
                <label htmlFor="lastname">LastName</label>
                <input type="text" maxLength={20} placeholder='jatt' value={lastname && lastname} name='lastname' onChange={(e) => { handleOnChange(e) }} />
              </div>

              <div>
                <label htmlFor="phone">Mobile</label>
                <input  type="tel" minLength={10}  maxLength={10} placeholder='7568445341' value={phone} name='phone' onChange={(e) => { handleOnChange(e) }} />
              </div>

              <div>
                <label htmlFor="">Hightest qualification</label>
                <input type="text" maxLength={50} placeholder='graduation' name='qualification' value={qualification && qualification} onChange={(e) => { handleOnChange(e) }} />
              </div>

              <div>
                <label htmlFor="">Experience</label>
                <input type="text" maxLength={50} placeholder='hod from 2years' name='experience' value={experience && experience} onChange={(e) => { handleOnChange(e) }} />
              </div>

              <div>
                <label htmlFor="">Department *</label>
                <Select className={styles.select} options={Array(data.department, "name", "id")} onChange={(e) => { onSelectDepartment(e) }} />
              </div>
              <div>
                <label htmlFor="">Course *</label>
                <Select className={styles.select} options={Array(data.courses, "name", "id")} onChange={(e) => { onSelectCourse(e) }} />
              </div>

            </div>

            <div className={styles.btn}>
              <>
                <button type='submit' >Update Profile</button>
                <div onClick={handleCancel} >Cancel</div>
              </>

            </div>
          </form>}
        </div> :

        <div className={styles.student}>

          {
            hide ? <div className={styles.profile}>
              {image ? <img src={IMG_URL + image} alt="img" />:<img src={PROFILEPIC} alt="profilepic" />}
              <div>
                <button onClick={() => { hideUpload() }}>{image ? "Change Profile" : "Add Profile Photo"}</button>
                <button onClick={handleCancel}>Update Information</button>
                <button onClick={logout}>Logout</button>

              </div>
            </div>

              : <div className={styles.upload}>
                <div>
                  <label htmlFor="">Profile Photo*</label>
                  <input type="file" name='photo' onChange={(e) => { handleFileChange(e) }} />
                </div>
                <div>
                  <button disabled={photo ? false : true} onClick={() => { upload() }}>upload</button>
                  <button onClick={() => { hideUpload() }}>Cancel</button>

                </div>
                <div>
                  <button disabled={!image && true} onClick={() => { deleteDp(hod?.hod?.id, image) }}>Delete Profile</button>
                </div>

              </div>

          }

          <div className={styles.info}>
            <div>
              <label>Name: </label>
              {hod?.name} {hod?.lastname}
            </div>
            <div>
              <label>Email: </label>
              {hod?.email}
            </div>
            <div>
              <label>Phone: </label>
              {hod?.phone || '-'}
            </div>
            <div>
              <label>Verified HOD: </label>
              {hod?.verify ? "Verified" : "Not Verified"}
            </div>

            <div>
              <label>Department Name: </label>
              {hod?.hod?.department?.name || '-'}
            </div>

            <div>
              <label>Course Name: </label>
              {hod?.hod?.course?.name || '-'}
            </div>

            <div>
              <label>Qualification: </label>
              {hod?.hod?.qualification || '-'}
            </div>
            <div>
              <label>Experience: </label>
              {hod?.hod?.experience || '-'}
            </div>

          </div>

          {
            !teacher?.length > 0 ? (hod?.verify) &&<button> NO teacher Of {hod?.hod?.department?.name} Department</button>:<button> Teacher Of {hod?.hod?.department?.name} Department  </button>
          }


          {select?.length > 0 && <div className={styles.searchStd}>
            <div className={styles.select}>
              <div>
                Search teacher
              </div>
              <Select className={styles.search} onChange={handleOnSelect} options={ArrayOfStd(select, 'name', 'id')} />
            </div>
            <button onClick={getAllteacher}>View All</button>
          </div>}

         { (load && <Loading/>) || <div className={styles.stdTable}>
            {teacher?.length > 0 && <table>
              <thead>
                <th>S.No</th>
                <th>Name</th>
                <th>Image</th>
                <th>Email</th>
                <th>phone</th>
                <th>Verified</th>
                <th>Block</th>
                <th>Delete</th>
              </thead>

              <tbody>
                {teacher?.map((c, i) => {

                  return (<tr key={i}>
                    <td>{i + 1}</td>
                    <td>{c.user.name} {c.user.lastname}</td>
                    <td><div className={styles.image} >{c.image?<img src={IMG_URL + c.image} alt="" />:<img src={PROFILEPIC} alt="" />}</div></td>
                    <td>{c.user.email}</td>
                    <td>{c.user.phone}</td>
                    <td ><div className={styles.delete} onClick={() => { verify(c.user.id) }}>{c.user?.verify ? "Verified" : "Not Verified"}</div></td>
                    <td ><div className={styles.block} onClick={() => { block(c.user.id) }}>{c.user.block ? "UnBlock" : "Block"}</div></td>
                    <td ><div onClick={()=>{deleteStd(c.user.id)}} className={styles.delete}>{"Delete"}</div></td>
                  </tr>)
                })
                }
              </tbody>
            </table>}

          </div>}


        </div>
      }

    </main>
  )
}

export default Hod;