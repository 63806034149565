import {BrowserRouter, Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Root from './component/view/rootroutes';
import { ROOT_URL } from './component/config';
import Auth from './component/view/auth';
import Notify from './component/services/notify';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <Notify />
      <Routes>
        <Route path={ROOT_URL+"/*"} element={ <Root/>}/>
        <Route path={ROOT_URL+"/auth/*"} element={ <Auth/>}/>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
