import React, { Component } from 'react'
import styles from './index.module.scss';
import { Axios, routes } from '../../../config';
import { Toast } from '../../../services/error/notification';
import Loader from '../../../loader';
import { error } from '../../../services/error';
import { Link } from 'react-router-dom';
import {BiSolidHide} from 'react-icons/bi'
import {FaEye} from 'react-icons/fa'
export class ForgetPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            getOtp: false,
            email: '',
            phone: '',
            password: "",
            otp: "",
            confirmPassword: "",
            loading: false,
            passwordHide: true,
            confirmHide: true
        }
    }


    resendOtp = (e) => {
        e.preventDefault();

        this.setState({ loading: true });

        const { email, getOtp } = this.state;
        const data = { email }

        Axios.post(routes.forgetPassword, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                    );
                    this.setState({ getOtp: !getOtp, loading: false })
                    return;
                }
            }).catch(err => {
                error(err)
                return console.log(err)
            }).finally(() => {
                this.setState({
                    loading: false,
                    otp: "",
                });
                return;
            })
    }

    toggleInput = (e) => {
        this.setState({ [e.target.name]: e.target.value });
        return;
    }

    seePassword = (name) => {
        if (name == 'password') {
            const { passwordHide } = this.state;
            this.setState({ passwordHide: !passwordHide })
            return
        } else {
            const { confirmHide } = this.state;
            this.setState({ confirmHide: !confirmHide })
            return
        }

    }

    resetPassword = (e) => {
        e.preventDefault()
        const { password, confirmPassword, otp, email } = this.state;
        if (!(password === confirmPassword)) {
            Toast(
                "Mismatch!", "Password mismatch", "danger"
            );
            return;
        }
        let data = { password, otp, email }
        this.setState({ loading: true })
        Axios.post(routes.resetPassword, data)
            .then(success => {
                if (success.data) {
                    Toast(
                        "Success!", success.data?.mess, "success"
                    );
                    window.location.href='/auth/login'
                    return;
                }
            }).catch(err => {
                error(err)
                return console.log(err)
            }).finally(() => {
                this.setState({
                    loading: false
                });
                return;
            })
    }

    render() {
        const { getOtp, loading,passwordHide,confirmHide } = this.state;
        if (loading) {
            return <Loader />
        }
        return (
            <div className={styles.forgetpassword}>
                <div className={styles.forget}>
                    <h2>Forget Password</h2>

                    {!getOtp ? <form onSubmit={this.resendOtp} className={styles.username}>


                        <div className={styles.email}>
                            <label htmlFor="email">Email Id</label>
                            <input type="email" required placeholder='example@gmail.com' name='email' onChange={(e) => this.toggleInput(e)} />
                        </div>


                        <div className={styles.getOtp}>
                            <button color='primary' type='submit' variant='contained'>Get OTP</button>
                        </div>
                        <p className="message">Go To <Link to={'/auth/login'}>Sign In</Link></p>

                    </form>
                        :
                        <form onSubmit={this.resetPassword} className={styles.username}>
                            <div className={styles.email}>
                                <label htmlFor="email">Enter Otp</label>
                                <input type="number" required name='otp' maxLength={10} onChange={(e) => { this.toggleInput(e) }} />
                            </div>
                            <div className={styles.email}>
                                <label htmlFor="password">New Password</label>
                                <div>
                                    <input required minLength={6} maxLength={50} type={passwordHide?"password":"text"} placeholder='strong password' name='password' onChange={(e) => { this.toggleInput(e) }} />
                                    {passwordHide?<span className='pointer' onClick={()=>{this.seePassword("password")}}><FaEye size={"1.2rem"}/></span>:
                                    <span  className='pointer' onClick={()=>{this.seePassword("password")}}><BiSolidHide size={"1.2rem"}/></span>}
                                </div>
                            </div>

                            <div className={styles.email}>
                                <label htmlFor="password">Confirm Password</label>
                                <div>
                                    <input type={confirmHide&&"password"} required maxLength={50}  minLength={6} placeholder='strong password' name='confirmPassword' onChange={(e) => { this.toggleInput(e) }} />
                                    {confirmHide?<span className='pointer' onClick={()=>{this.seePassword("confirm")}}><FaEye size={"1.2rem"}/></span>:
                                    <span  className='pointer' onClick={()=>{this.seePassword("confirm")}}><BiSolidHide size={"1.2rem"}/></span>}
                                </div>
                            </div>
                            <button type='submit' color='primary' variant='contained' >reset password</button>
                            <p className="message">Go To <Link to={'/auth/login'}>Sign In</Link></p>
                        </form>

                    }
                </div>
            </div>
        )
    }
}

export default ForgetPassword