import React from 'react';
import styles from './index.module.scss'; // Import your SCSS module
import { IMG_URL, PROFILEPIC } from '../config';

const TeachersProfile = ({ teachers }) => {
  return (
    <div className={styles.profile}>
      {teachers?.image?<img src={IMG_URL + teachers.image} alt={teachers.name} />:<img src={PROFILEPIC} alt="profile" />}
      <div className={styles.details}>
        <h3>{teachers?.user?.name} {teachers?.user?.lastname}</h3>
        {teachers.department?.name && <p>Hod  Of  {teachers?.department?.name + ' department'}  </p>}
        {teachers.qualification && <p>Qualification : {teachers.qualification}</p>}
        {(teachers.year && teachers?.course?.name )&& <p>Class Teacher Of  {teachers?.course?.name + ' ' + teachers?.year + " year"}  </p>}
      </div>
    </div>
  );
};

export default TeachersProfile;
